import { Card, CardProps } from "./Card";

export type MainCardProps = CardProps;

export const MainCard: React.FC<MainCardProps> = ({ children, style, ...props }) => {
  return (
    <Card
      style={{
        width: "60%",
        minWidth: 300,
        maxWidth: 800,
        alignSelf: "center",
        margin: "auto",
        alignItems: "center",
        padding: "20 0 20 0",
        ...style
      }}
      {...props}
    >
      {children}
    </Card>
  );
};
