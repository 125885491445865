import React from "react";
import "./App.scss";
import { Card } from "./features/Cards/Card";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./Router";

function App() {
  return (
    <Card style={{ height: "100%", width: "100%", margin: "auto" }}>
      <RouterProvider router={appRouter} />
    </Card>
  );
}

export default App;
