import React from "react";
import { Card } from "../Cards/Card";
import { MainCard } from "../Cards/MainCard";
import awsDynamo from "../../assets/tech-stack/aws-dynamo-db.png";
import azure from "../../assets/tech-stack/azure.png";
import sql from "../../assets/tech-stack/mssql.png";
import gcp from "../../assets/tech-stack/gcp.png";
import csharp from "../../assets/tech-stack/c-sharp.png";
import js from "../../assets/tech-stack/js.png";
import ts from "../../assets/tech-stack/ts.png";
import tf from "../../assets/tech-stack/tensorflow.png";
import vscode from "../../assets/tech-stack/vscode.png";
import vs from "../../assets/tech-stack/vs.png";
import dotnet from "../../assets/tech-stack/dot-net.png";
import gh from "../../assets/tech-stack/github.png";
import ghCopilot from "../../assets/tech-stack/gh-copilot.jpg";
import nestjs from "../../assets/tech-stack/nestjs.png";
import expressjs from "../../assets/tech-stack/expressjs.png";
import nx from "../../assets/tech-stack/nx.png";
import react from "../../assets/tech-stack/react.png";
import expo from "../../assets/tech-stack/expo.png";

const logos = [
  awsDynamo,
  azure,
  sql,
  gcp,
  vscode,
  vs,
  dotnet,
  csharp,
  gh,
  ghCopilot,
  js,
  ts,
  tf,
  nestjs,
  expressjs,
  nx,
  react,
  expo
];

export const Home: React.FC = () => {
  return (
    <MainCard
      style={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "80%",
        maxWidth: "800px"
      }}
    >
      <Card
        isHorizontal
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
          flexWrap: "wrap",
          minHeight: "40%"
        }}
      >
        {logos.map((logo) => {
          return (
            <Card
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: 16,
                marginRight: 8
              }}
            >
              <img width={80} src={logo} alt="Tech" style={{ marginLeft: 4, marginRight: 12 }} />
            </Card>
          );
        })}
      </Card>
    </MainCard>
  );
};
