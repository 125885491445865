import React from "react";
import { Text } from "@fluentui/react-components";
import { ChatBubblesQuestion32Regular } from "@fluentui/react-icons";
import { MainCard } from "../Cards/MainCard";

export const Support: React.FC = () => {
  return (
    <MainCard>
      <ChatBubblesQuestion32Regular style={{ margin: 16 }} />
      <Text size={500} style={{ marginBottom: 20, textAlign: "center" }}>
        For support and other inquiries, please contact me at
      </Text>
      <Text size={500}>support@piguixr.com</Text>
    </MainCard>
  );
};
