import {
  ToolbarRadioButton,
  ToolbarRadioGroup,
  Card as FluentCard,
  Text,
  Tooltip,
  Toolbar
} from "@fluentui/react-components";
import * as React from "react";
import { Home24Regular, PersonQuestionMark24Regular } from "@fluentui/react-icons";
import { Card } from "../Cards/Card";
import piguiLogo from "../../assets/pigui-logo.png";
import { useLocation, useNavigate } from "react-router-dom";

export const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathSections = location.pathname.split("/");
  const activeMenu = pathSections.length <= 2 ? "home" : pathSections[2];

  return (
    <Card style={{ width: "100%" }}>
      <FluentCard
        style={{
          flexDirection: "row",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          flexWrap: "wrap",
          padding: 6,
          justifyContent: "space-between"
        }}
      >
        <Card style={{ flexDirection: "row", alignItems: "center" }}>
          <img
            width={50}
            src={piguiLogo}
            alt="Pigui Logo"
            style={{ marginLeft: 4, marginRight: 12 }}
          />
          <Card>
            <Text size={500}>Pigui</Text>
            {/* <Text size={300} italic>
              How well can you tell real articles from fake news?
            </Text> */}
          </Card>
        </Card>
        <Card style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
          <Toolbar
            defaultCheckedValues={{
              menu: [activeMenu]
            }}
            onCheckedValueChange={(e, data) => {
              navigate(data.checkedItems[0]);
            }}
          >
            <ToolbarRadioGroup>
              <Tooltip content="Home" relationship="label" positioning="below">
                <ToolbarRadioButton
                  aria-label="Home"
                  name="menu"
                  value="home"
                  icon={<Home24Regular />}
                  style={{ marginRight: 8 }}
                />
              </Tooltip>
              <Tooltip content="About" relationship="label" positioning="below">
                <ToolbarRadioButton
                  aria-label="About"
                  name="menu"
                  value="about"
                  icon={<PersonQuestionMark24Regular />}
                />
              </Tooltip>
            </ToolbarRadioGroup>
          </Toolbar>
        </Card>
      </FluentCard>
    </Card>
  );
};
