import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import paypalReducer from "./paypalReducer";

const persistedState = loadState();
export const store = configureStore({
  reducer: {
    paypal: paypalReducer
  },
  preloadedState: persistedState ?? { paypal: { isDonating: false } }
});

store.subscribe(() => {
  saveState({
    ...store.getState()
  });
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

function saveState(state: RootState) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.error(err);
  }
}

function loadState() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(err);
    return null;
  }
}
