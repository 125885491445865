import "./Card.scss";
import classNames from "classnames";

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  isHorizontal?: boolean;
  isWrapped?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const Card: React.FC<CardProps> = ({ isHorizontal, isWrapped, children, ...props }) => {
  const classes = classNames("card", { horizontal: isHorizontal }, { wrap: isWrapped });
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
