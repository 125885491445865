import { Card } from "./Cards/Card";
import { Text } from "@fluentui/react-components";
import { MainCard } from "./Cards/MainCard";

export const NotFound: React.FC = () => {
  return (
    <MainCard>
      <Card style={{ minHeight: 300, justifyContent: "center", alignItems: "center" }}>
        <Text size={600} style={{ marginBottom: 20 }}>
          Page not found
        </Text>
      </Card>
    </MainCard>
  );
};
