import React from "react";
import { Link, Text } from "@fluentui/react-components";
import { MainCard } from "../Cards/MainCard";
import linkedIn from "../../assets/linkedin-small.png";

export const About: React.FC = () => {
  return (
    <MainCard>
      <Text size={400} style={{ marginBottom: 20 }} weight="semibold">
        Let's connect if you want to know more about me or my projects:
      </Text>
      <Link
        style={{ marginBottom: 40, marginLeft: 6, display: "flex", alignItems: "end" }}
        href="https://linkedin.com/in/gui5ilv4"
        target="_blank"
      >
        <img width={30} src={linkedIn} alt="LinkedIn" style={{ marginLeft: 0, marginRight: 12 }} />
        <Text size={400}>linkedin.com/in/gui5ilv4</Text>
      </Link>
    </MainCard>
  );
};
