import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PayPalStore {
  isDonating: boolean;
}

export const initialState: PayPalStore = {
  isDonating: false
};

export const paypalSlice = createSlice({
  name: "paypal",
  initialState,
  reducers: {
    setIsDonating: (state, action: PayloadAction<boolean>) => {
      state.isDonating = action.payload;
    }
  }
});

export const { setIsDonating } = paypalSlice.actions;
export default paypalSlice.reducer;
