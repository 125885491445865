import { Navigate, createBrowserRouter } from "react-router-dom";
import { AppRoot } from "./features/AppRoot";
import { Home } from "./features/Home/Home";
import { NotFound } from "./features/NotFound";
import { Support } from "./features/Support/Support";
import { About } from "./features/About/About";

export const appRouter = createBrowserRouter([
  {
    element: <AppRoot />,
    children: [
      {
        path: "/home",
        element: <Navigate to="/" replace />
      }
    ]
  },
  {
    element: <AppRoot />,
    children: [
      {
        path: "/",
        element: <Home />
      }
    ]
  },
  {
    element: <AppRoot />,
    children: [
      {
        path: "/support",
        element: <Support />
      }
    ]
  },
  {
    element: <AppRoot />,
    children: [
      {
        path: "/about",
        element: <About />
      }
    ]
  },
  {
    element: <AppRoot />,
    children: [
      {
        path: "*",
        element: <NotFound />
      }
    ]
  }
]);
